<template>
  <ul>
    <li v-if="isLoggedIn" class="nav-item dropdown user-dropdown-menu">
      <a class="d-none d-md-block " :href="'/account'">
        <span class="text-color-orange" v-if="isSalesRep || isESales">
          <i class="fa-solid fa-user text-color-orange mr-2"></i>&nbsp;{{ loggedinUserDetails.fname }}
        </span>
        <span v-else>
          <i class="fa-solid fa-user mr-2"></i>&nbsp;{{ loggedinUserDetails.fname }}
        </span>
      </a>
      <a class="d-md-none d-block" v-if="isLoggedIn" :href="'javascript:void(0)'">
        <span class="text-color-orange" v-if="isSalesRep || isESales">
          <i class="fa-solid fa-user text-color-orange mr-2"></i>&nbsp;{{ loggedinUserDetails.fname }}
        </span>
        <span v-else>
          <i class="fa-solid fa-user mr-2"></i>&nbsp;{{ loggedinUserDetails.fname }}
        </span>
      </a>
      <ul class="user-menu">
        <li>
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#invoice-history">
            <i class="fa-solid fa-file-invoice-dollar mr-2"></i><span>Invoice History</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#processing-online-orders">
            <i class="fa-solid fa-cart-shopping mr-2"></i><span>Processing Online Orders</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#invoice-credit-history">
            <i class="fa-solid fa-file-invoice mr-2"></i><span>Invoice Credit History</span>
          </a>
        </li>
        <li v-if="canViewLoyalty">
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#petals-rewards">
            <i class="fa-solid fa-leaf"></i><span>Petals Rewards</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#quotes">
            <i class="fa-solid fa-newspaper mr-2"></i><span>Quotes</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#product-planner-quotes">
            <i class="fa-solid fa-calculator mr-2"></i><span>Product Planner</span></a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between control-tabs" href="/account#wish-lists">
            <i class="fa-solid fa-heart mr-2"></i><span>Wish Lists</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between" href="https://calendly.com/mayesh/mayesh-market-demo" target="_blank" >
            <i class="fa-solid fa-desktop mr-2"></i><span>Schedule Live Demo</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between" href="/change-password">
            <i class="fa-solid fa-key mr-2"></i><span>Password</span>
          </a>
        </li>
        <li>
          <a class="nav-link d-flex justify-content-between" href="#" @click="doLogout"><i class="fa-solid fa-user mr-2"></i><span>Logout</span></a>
        </li>
      </ul>
    </li>
    <li v-else>
      <router-link :to="{ name: 'Login' }">Login</router-link>
    </li>
    <li v-if="isLoggedIn && wishListCount > 0">
      <a href="/account#wish-lists"><i class="fa-solid fa-heart"></i></a>
    </li>
    <li><a href="/blog">Blog</a></li>
    <li><a href="/contact-us">Contact Us</a></li>
    <li v-if="!isLoggedIn"><a href="/register">Register</a></li>
    <li v-if="canEnrollLoyalty" class="d-none d-lg-flex">
      <a href="/petals-rewards">Join Petals Rewards</a>
    </li>
  </ul>
  <ul v-if="canEnrollLoyalty" class="d-lg-none">
    <li>
      <a href="/petals-rewards">Join Petals Rewards</a>
    </li>
  </ul>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { misc } from "@/mixins";
export default {
  name: "HeaderSection",
  mixins: [misc],
  computed: {
    ...mapGetters(["loggedinUserDetails", "isSalesRep", "isESales", "canEnrollLoyalty", "canViewLoyalty"]),

    name() {
      return localStorage.getItem("name");
    },
  },
  methods: {
    ...mapActions(["doLogout"]),

    toAccountPage(hash) {
      this.$router.push({
        path: "/account",
        hash: `#${hash}`,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width:992px) {
  .user-dropdown-menu a.d-md-none{
    display:block !important;
  }

  .user-dropdown-menu a.d-md-block{
    display:none !important;
  }
}
@media screen and (max-width:768px) {
  .user-dropdown-menu a.d-md-none{
    display:block !important;
  }

  .user-dropdown-menu a.d-md-block{
    display:none !important;
  }
}
a {
  text-decoration: none;
}
ul.user-menu li a span {
  padding-left: 10px;
}

.text-color-orange {
  color:#F48062;
}
</style>