<template>
  <footer class="mt-md-5">
    <!-- warning message modal -->
    <div id="warning-pop" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Cart is Expiring</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Are you still there? Your cart is about to expire in 5 minutes.
              Please complete your purchase by checking out. If your cart
              expires, you will need to start over. 
            </p>
          </div>
          <div class="modal-footer text-center">
            <!--<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>-->
            <button
              type="button"
              onclick="location.href='https://www.mayesh.com/checkout?type=';"
              class="btn btn-default m-auto"
            >
              Checkout Now
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="warning-pop-cutoff" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Expiring</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              There are products in your cart that are about to be removed due
              to the scheduled cutoff time.
            </p>
          </div>
          <div class="modal-footer text-center">
            <!--<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>-->
            <button
              type="button"
              onclick="location.href='https://www.mayesh.com/checkout?type=';"
              class="btn btn-default m-auto"
            >
              Checkout Now
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="warning-changing-date" class="modal fade" role="dialog">
      <div class="modal-dialog modal-lg">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Chaging Date</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              By changing dates, you will lose the products that are in your
              cart, are you sure to go ahead?
            </p>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              onclick="location.href='https://www.mayesh.com/checkout?type=';"
              class="btn btn-default"
            >
              Checkout Now
            </button>
            <button
              type="button"
              class="btn btn-default"
              data-dismiss="modal"
              id="chaging-date-yes"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-default"
              data-dismiss="modal"
              id="chaging-date-no"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End warning modal -->
    <div
      class="mb-5 mt-0"
      v-for="(section, index) in globalFooterSection"
      :key="index"
      v-html="section.body"
    ></div>
    <div class="accordion pb-0 mb-0" id="footerAccordion">
      <div class="row gx-md-5">
        <div class="col-12 col-lg mb-0 mb-lg-4" v-for="(filter, key) in footerSection" :key="key">
          <input type="radio" :id="`footerAccordionOption_${key}`" :value="filter.id" v-model="selected"  @click="uncheck(filter.id)" class="d-none" name="footerAccordionOptions">
          <p class="footer-heading" id="headingOne">
            <label
              class="d-flex flex-nowrap align-items-center justify-content-between" :for="`footerAccordionOption_${key}`"
            >
              <span class="me-1">{{ filter.name }}</span>
              <i class="fa-solid fa-plus d-lg-none footer-plus-icon"></i>
            </label>
          </p>
          <div
            id="collapseMayeshMarket"
          >
            <ul>
              <li
                v-for="(links, linkkey) in filter.footer_links"
                :class="`${links.css_class}`"
                :key="linkkey"
              >
                <a
                  v-if="links.blank == 1"
                  target="_blank"
                  :href="links.seo_url"
                >
                  {{ links.name }}</a
                >
                <a v-else :href="links.seo_url"
                >
                  {{ links.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">      
      <a href="/">
        <img
          :src="`${imagePath}${companyLogo}`"
          class="img-fluid mx-auto mx-md-0 mb-3 mt-3 logo-footer"
          alt="Logo"
        />
      </a>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
export default {
  name: "FooterSection",
  mounted() {
    this.fetchGlobal();
    this.fetchLiks();
    this.updatePrograms();
    this.getCompanyLogo()
    this.emitter.on("updatePrograms", () => {
      this.updatePrograms();
    });
  },
  data() {
    return {
      footerSection: [],
      programClasses: [],
      companyLogo: '',
      globalFooterSection: [],
      selected: 0,
      previouslySelected: 0
    };
  },
  mixins: [misc],
  computed: {
    
  },
  methods: {

    uncheck: function(val) { 
      if (val === this.previouslySelected) {
        this.selected = false;
        this.previouslySelected = false
      } 
        this.previouslySelected = val;
      
    },

    getCompanyLogo() {
      if (localStorage.getItem("company_logo") == null) {
        setTimeout(() => {
          this.companyLogo = localStorage.getItem("company_logo");
        }, 1000);
      } else {
        this.companyLogo = localStorage.getItem("company_logo");
      }
      
    },

    fetchGlobal() {
      axios
        .post(process.env.VUE_APP_API_BASE + "auth/getGlobalModules", 
        {
          location: 'bottom',
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.globalFooterSection = response.data.sections;
        });
    },
    fetchLiks() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getFooterLinks", {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.footerSection = response.data.page;
        });
    },

    updatePrograms() {
      axios
      .post(process.env.VUE_APP_API_BASE + "auth/getProgramStatuses", 
        {
        },
        {
          headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (this.isLoggedIn) {
          this.programClasses = response.data.classes;
          setTimeout(() => {
            this.programClasses.forEach(program => {
              let displayElement = 'd-none';
              if (program.status == 1) {
                displayElement = 'd-block';
              }
              let elements = document.querySelectorAll('.' + program.css_class)
              if (elements.length > 0) {
                elements.forEach((element) => {
                  if (displayElement == 'd-block') {
                    element.classList.remove('d-none')
                  } else {
                    element.classList.remove('d-block')
                  }
                  element.classList.add(displayElement)
                })
              }
          });  
          }, 1000);
        } else {
          setTimeout(() => {
            this.programClasses = response.data.classes;
            this.programClasses.forEach(program => {
              let displayElement = 'd-block';
              if (program.require_login == 1) {
                displayElement = 'd-none';
              }
              let elements = document.querySelectorAll('.' + program.css_class)
              if (elements.length > 0) {
                elements.forEach((element) => {
                  if (displayElement == 'd-block') {
                    element.classList.remove('d-none')
                  } else {
                    element.classList.remove('d-block')
                  }
                  element.classList.add(displayElement)
                })
              }
          });  
          }, 1000);
        }
        
      });
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.logo-footer {
  width: 293px;
  height: 44px;
}
</style>