<template>
  <component v-bind:is="compToLoad" :slug="slug" :prevUrl="prevUrl"></component>
</template>

<script>
import { ContentPage, ProductDetail, Category, CategoryType, Location, Locations, HolidayHolland } from "@/pages";
import { misc } from "@/mixins";
import axios from "axios";
import { markRaw } from "vue";
const components = markRaw({ ContentPage, ProductDetail, Category, CategoryType, Location, Locations, HolidayHolland });
export default {
  name: "DynamicPage",
  mixins: [misc],
  data() {
    return {
      components,
      compToLoad: "",
      slug: "",
      prevUrl: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevUrl = from;
      vm.loadComp(to.params.pageSlug, to.params.pageSlug2);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.loadComp(to.params.pageSlug, to.params.pageSlug2);
    next();
  },
  methods: {
    loadComp(pageSlug, pageSlug2) {
      if (pageSlug2 != undefined) {
        pageSlug += '/' + pageSlug2;
      }
      this.getPageType(pageSlug).then((resp) => {
        if ("redirect" in resp && resp.redirect) {
          window.location.replace('/' + resp.new_url);
        } else if (resp.type === "location") {
          this.compToLoad = markRaw(Location);
        } else if (resp.type === "locations") {
          this.compToLoad = markRaw(Locations);
        } else if (resp.type === "page") {
          this.compToLoad = markRaw(ContentPage);
        } else if (resp.type === "product") {
          this.compToLoad = markRaw(ProductDetail);
        } else if (resp.type === "category_type") {
          this.compToLoad = markRaw(CategoryType);
        } else if (resp.type === "category") {
          this.compToLoad = markRaw(Category);
        } else if (resp.type === "holiday_holland") {
          this.compToLoad = markRaw(HolidayHolland);
        }

        this.slug = pageSlug;
      });
    },
    getPageType(pageSlug) {
      let resp = {};
      return this.fetchPageType(pageSlug)
        .then((response) => {
          resp = response.data;
          return Promise.resolve(resp);
        })
        .catch(() => {});
    },
    fetchPageType(pageSlug) {
      const headers = {
        "Content-type": "application/json",
      };

      if (this.isLoggedIn) {
        headers["Authorization"] = "Bearer " + localStorage.getItem("token");
      } else {
        headers["Access-Control-Allow-Origin"] = "*";
      }

      return axios.post(
        `${process.env.VUE_APP_API_BASE}auth/getPageType`,
        {
          slug: pageSlug,
        },
        {
          headers,
        }
      );
    },
  },
};
</script>