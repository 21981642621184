<template>
  <header>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <div id="header-top-menu-bar" class="top-menu-bar fixed-header">
      <div id="logged-on-behalf-customer" v-if="isLoggedIn && (isSalesRep || isESales)">
        <p class="text-center text-white">You are logged in as {{ loggedinUserDetails.name }} - {{ loggedinUserDetails.company_name }} ({{ loggedinUserDetails.company_code }}) <span v-if="isESales">with 0% markup</span></p>
      </div>
      <MyBanners position="top" />
      <div class="container">
        <div class="row top-menu-primary">
          <div class="col-md-4">
            <div class="welcome-text">
              <p
                style="
                  color: #606060;
                  font-size: 14px;
                  font-weight: 500;
                  padding-top:5px;
                  letter-spacing: 1.5px;
                "
              >
                <a href="/" style="color: #606060; padding: 6px 6px 15px 0px;">{{ getCompanyName }}</a>
              </p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="top-menu-link">
              <MyHeader />
            </div>
          </div>
        </div>

        <div
          v-if="isLoggedIn || quoteItemCount > 0"
          class="top-menu-primary pb-7 text-center text-md-end"
        >
          <ul class="nav d-inline-flex" v-if="cartMeta.count">
            <span class="d-block">
              {{ countdownText }} time left to shop
              <span id="hint">
                <!-- <tooltips
                  text="Kindly check out before this timer expires. Since this is a live inventory items will be automatically returned to inventory."
                  >
                </tooltips> -->

                <Popper
                  class="dark"
                  :arrow="true"
                  :hover="true"
                  content="Kindly check out before this timer expires. Since this is a live inventory items will be automatically returned to inventory."
                >
                  <i class="fa-regular fa-circle-question ml-2"></i>
                </Popper>
              </span>

              <div ref="miniCartCont" class="d-inline-block">
                <a
                  ref="miniCartLink"
                  role="button"
                  id="miniCartLink"
                  class="miniCartLink d-block"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <span class="fa-solid fa-cart-shopping"></span>
                  ({{ cartMeta.count }})
                </a>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="miniCartLink">
                  <div class="mini-cart-total mb-2">
                    <a href="/shopping-cart" class="btn checkout-btn">View Cart</a>
                    <a v-if="getCurrentShoppingLists && getCurrentHolHolidays"
                      :href="`/checkout?type=${getCurrentCartTypes}&list=${getCurrentShoppingLists}&hol_holiday=${getCurrentHolHolidays}`"
                      class="btn btn-primary">Checkout</a>
                    <a v-else-if="getCurrentShoppingLists"
                      :href="`/checkout?type=${getCurrentCartTypes}&list=${getCurrentShoppingLists}`"
                      class="btn btn-primary">Checkout</a>
                    <a v-else-if="getCurrentHolHolidays"
                      :href="`/checkout?type=${getCurrentCartTypes}&hol_holiday=${getCurrentHolHolidays}`"
                      class="btn btn-primary">Checkout</a>
                    <a v-else :href="`/checkout?type=${getCurrentCartTypes}`" class="btn btn-primary">Checkout</a>
                  </div>
                  <div class="row">
                    <div class="all_cart_containers col-12">
                      <template v-for="(cartType, index) in cart" :key="index">
                        <p v-if="cartType.header.program_type.toUpperCase() == 'SBL'" class="mb-2">
                          <b>{{ cartType.header.shopping_list_name }}</b>
                        </p>
                        <p v-if="cartType.header.program_type.toUpperCase() == 'DBL2'" class="mb-2">
                          <b>{{ cartType.header.hol_holiday_name }}</b>
                        </p>
                        <p v-else-if="cartType.header.program" class="mb-2">
                          <b>{{ cartType.header.program }}</b>
                        </p>
                        <ul class="minicart-list mb-2">
                          <li
                            v-for="(product, index) in cartType.products"
                            :key="index"
                          >
                            <figure>
                              <span v-if="product.program_type != 'DBL' && product.program_type != 'DBL2'">
                                <router-link :to="`/${product.seo_url}`">
                                  <img
                                    v-if="
                                      product.image && product.image != null
                                    "
                                    :src="`${imagePath}` + product.image"
                                    class="1111"
                                    :alt="product.name"
                                  />
                                  <img
                                    v-else
                                    :src="
                                      `${imagePath}` +
                                      getDefaultImage(product.product_type_id)
                                    "
                                    class="ssdsdsd"
                                    :alt="product.name"
                                  />
                                </router-link>
                              </span>
                              <span v-else>
                                <img
                                  v-if="product.product_image"
                                  :src="product.product_image"
                                  class="img-thumbnail"
                                  :title="product.product_name"
                                  :alt="product.product_name"
                                />
                                <img
                                  v-else
                                  :src="
                                    `${imagePath}` +
                                    getDefaultImage(product.product_type_id)
                                  "
                                  class="img-thumbnail"
                                  :title="product.product_name"
                                  :alt="product.product_name"
                                />
                              </span>
                            </figure>
                            <div class="mini-cart-text">
                              <h4 v-if="product.program_type != 'DBL' && product.program_type != 'DBL2'">
                                <router-link :to="`/${product.seo_url}`">
                                  {{ product.name }}
                                </router-link>
                              </h4>
                              <h4 v-else>{{ product.product_name }}</h4>
                              <div class="minicart-price-qty">
                                <span class="price"
                                  >${{ product.unit_price }}</span
                                >
                                <span class="qty box_units_container"
                                  >Qty:
                                  <small
                                    v-if="
                                      product.program_type == 'LAL' ||
                                      product.program_type == 'PRB' || 
                                      product.program_type == 'WSP' || 
                                      product.program_type == 'SBL'
                                    "
                                    >{{ product.quantity }}
                                    {{
                                      product.quantity > 1
                                        ? product.sold_by_plural
                                        : product.sold_by_singular
                                    }}
                                  </small>
                                  <small
                                    v-if="
                                      product.program_type == 'MDB' ||
                                      product.program_type == 'FDB' ||
                                      product.program_type == 'DBL' ||
                                      product.program_type == 'DBL2' ||
                                      product.program_type == 'MMT'
                                    "
                                    >{{ product.quantity }}
                                    {{
                                      product.quantity > 1
                                        ? product.box_plural
                                        : product.box_singular
                                    }}
                                  </small>
                                  <small v-if="product.program_type == 'HGS'">
                                    {{ product.quantity }}
                                    <span
                                      v-if="product.sold_by_singular != 'each'"
                                    >
                                      {{
                                        product.quantity > 1
                                          ? product.sold_by_plural
                                          : product.sold_by_singular
                                      }}
                                    </span>
                                  </small>
                                </span>
                                <delete-product
                                  :itemId="product.cartItemId"
                                  :product="product"
                                ></delete-product>
                                <!-- <a href="javascript:void()" class="remove-item" onclick="confirm('Are you sure?') ? cart.remove('254992') : false;"><i class="fa-regular fa-trash-alt"></i></a> -->
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="mini-cart-total">
                          <table class="text-end">
                            <tbody>
                              <tr>
                                <td>Subtotal</td>
                                <td>${{ cartType.subTotal }}</td>
                              </tr>
                              <tr v-if="cartType.taxes > 0">
                                <td>Taxes</td>
                                <td>${{ cartType.taxes }}</td>
                              </tr>
                              <tr v-if="cartType.shipping_selected">
                                <td>{{ cartType.shipping_selected.shipping_method_name }}</td>
                                <td>{{ shippingCost(cartType.shipping_selected) }}</td>
                              </tr>
                              <tr>
                                <td>Total</td>
                                <td>${{ cartType.total }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-if="isEnrolledLoyalty && cartType.loyalty != ''" class="p-4 peach-color text-center">{{ cartType.loyalty }}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <modal
                v-if="deleteProdModal.show"
                :closeFn="hideDeleteProdWarn"
                closeBtn="cancel"
                :submitFn="deleteProduct"
                submitBtn="ok"
              >
                Are you sure, you want to delete product?
              </modal>

              <modal
                v-if="minicart.extendCartModal.show"
                :closeFn="hideExtendCartModal"
                closeBtn="No"
                :submitFn="extendCartTimer"
                submitBtn="Yes"
              >
                <template v-slot:header>
                  {{ minicart.extendCartModal.heading }}
                </template>
                {{ minicart.extendCartModal.content }}
              </modal>

              <modal
                v-if="minicart.warnModal.show"
                :closeFn="hideWarnModal"
                closeBtn="CHECKOUT NOW"
              >
                <template v-slot:header>
                  {{ minicart.warnModal.heading }}
                </template>
                {{ minicart.warnModal.content }}
              </modal>
            </span>
          </ul>
          <ul class="nav d-inline-flex" v-if="quoteItemCount">
            <li class="nav-item">
              <a
                class="nav-link text-reset"
                href="/quote-lists"
                id="quotes-total"
              >
                Quote
                <span class="hidden-xs hidden-sm hidden-md"
                  >({{ quoteItemCount }})</span
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="header-top-menu" class="header-top">
      <InstallPWA />
      <nav class="navbar navbar-expand-sm navbar-light py-0">
        <div class="container">
          <div class="row g-0 col-md-12 align-items-center w-100">
            <div class="col-auto">
              <div class="logo m-0">
                <a href="/">
                  <img
                    :src="`${imagePath}${getCompanyLogo}`"
                    title="Mayesh Wholesale Florist, Inc."
                    alt=" Mayesh Wholesale Florist, Inc."
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div class="col-auto ms-auto">
              <a
                href="#"
                @click.prevent="toggleMobileMenu()"
                aria-haspopup="true"
                role="button"
                tabindex="0"
                class="d-md-none slicknav_btn slicknav_collapsed"
                style="outline: none"
              >
                <span class="slicknav_icon">
                  <span class="slicknav_icon-bar"></span>
                  <span class="slicknav_icon-bar"></span>
                  <span class="slicknav_icon-bar"></span>
                </span>
              </a>
            </div>
            <div class="col-md ms-md-5 mt-3 mt-md-0">
              <div class="header-search-box position-relative">
                <form
                  id="search_form_top"
                  action=""
                  method="get"
                  @submit.prevent="submitSearchForm"
                >
                  <div class="form-group mb-2 mb-md-0">
                    <input
                      type="text"
                      class="form-control"
                      v-model="decodedSearch"
                      id="search_input"
                      placeholder="SEARCH MAYESH.COM"
                    />
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      id="clearSearch"
                      v-if="showClearBtn"
                      @click="clearSearchForm"
                    ></button>
                    <div id="srchBtn" @click="submitSearchForm">
                      <i class="fa-solid fa-magnifying-glass global-search-icon"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="header-bottom" :class="{ open: displayMobileMenu, 'top-134': installPWAVisible }">
      <Megamenu></Megamenu>
    </div>
    <div class="container">
      <Breadcrumb></Breadcrumb>
      <div class="mt-0">
        <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
          <span v-html="flashAlert.msg"></span>
        </flash-alert>
      </div>
    </div>
    <modal
      v-if="deletePPModal.show"
      :closeFn="hideDeletePPWarn"
      closeBtn="cancel"
      :submitFn="deletePP"
      submitBtn="ok"
    >
      Are you sure, you want to delete product planner?
    </modal>

    <PopUp v-if="currentPopup.show" :closeFn="hidePopup" :htmlContent= currentPopup.content></PopUp>
  </header>
</template>

<script>
import { Header as MyHeader, Megamenu } from "@/layouts";
import Popper from "vue3-popper";
import {
  Breadcrumb,
  DeleteProduct,
  FlashAlert,
  Modal,
  PopUp,
  InstallPWA,  
  MyBanners,
  //Tooltips,
} from "@/components";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import axios from "axios";
import { misc } from "@/mixins";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "StickyHeader",
  mixins: [misc],
  components: {
    Breadcrumb,
    MyHeader,
    Megamenu,
    FlashAlert,
    DeleteProduct,
    Modal,
    PopUp,
    Popper,
    InstallPWA,
    MyBanners
    //Tooltips,
  },
  mounted() {
    this.fetchSettings();
    this.countDownTimer();
    this.initMiniCart();
    this.fetchUnitType();
    this.getPopups();
    this.getProgramSettings();
    if (this.isLoggedIn) {
      this.fetchQuoteList();
      this.fetchCartAndMeta();
      this.fetchWishList();
    }

    this.getCurrentCartTypes;
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });

    this.emitter.on("hideLoader", () => {
      this.hideLoader();
    });

    this.emitter.on("cartUpdated", () => {
      this.hideLoader();
    });

    this.emitter.on("deletedProduct", (payload) => {
      this.productDeleted(payload);
    });

    this.emitter.on("deletedPP", (payload) => {
      this.PPDeleted(payload);
    });

    this.emitter.on("displayFlash", (payload) => {
      this.displayFlash(payload);
    });

    this.emitter.on("addToWishlist", (payload) => {
      this.addToWishlist(payload);
    });

    this.emitter.on("deleteFromWishlist", (payload) => {
      this.deleteFromWishlist(payload);
    });

    this.emitter.on("deletedProductGA", (payload) => {
      let productObj = {
        event_category: "purchase",
        event_label: 'remove from cart',
        item_id: payload.product.items.product_id,
        item_name: payload.product.items.name,
        affiliation: payload.product.items.farm_name,
        item_list_id: payload.product.items.programtypeId,
        item_list_name: payload.product.items.programtypeName,
        item_category: payload.product.items.category_name,
        item_category2: payload.product.items.variety_name,
        item_variant: payload.product.items.color_name,
        item_category3: payload.product.items.grade_name,
        quantity: parseInt(payload.product.items.quantity),
        price: parseFloat(payload.product.items.price_per_unit),
        currency: "USD",
      }
      this.$gtag.event("remove_from_cart", productObj);
    });
    
    this.emitter.on("getPopups", () => {
      this.getPopups();
    });

    let elmnt = document.querySelector("#header-top-menu-bar");
    let marginTop = elmnt.offsetHeight;
    document.querySelector("#header-top-menu").style.marginTop = marginTop + "px";

    let resizeObserver = new ResizeObserver(() => {
      let elment = document.querySelector("#header-top-menu-bar");
      let marginTop = elment.offsetHeight;
      document.querySelector("#header-top-menu").style.marginTop = marginTop + "px";
    });

    resizeObserver.observe(elmnt);
  },
  // beforeUnmount() {
  //   if (this.$refs.miniCartLink) {
  //     this.$refs.miniCartLink.removeEventListener(
  //       "mouseenter",
  //       this.showMiniCart
  //     );

  //     this.$refs.miniCartCont.removeEventListener(
  //       "mouseleave",
  //       this.hideMiniCart
  //     );
  //   }
  // },

  beforeUnmount() {
    if (this.$refs.miniCartLink) {
      if (this.isTouchDevice()) {
        this.$refs.miniCartLink.removeEventListener("click", this.toggleMiniCart);
      } else {
        this.$refs.miniCartLink.removeEventListener("click", this.shoppingCart);

        this.$refs.miniCartLink.removeEventListener(
          "mouseenter",
          this.showMiniCart
        );

        this.$refs.miniCartCont.removeEventListener(
          "mouseleave",
          this.hideMiniCart
        );
      }
    }
  },

  data() {
    return {
      searchString: "",
      showClearBtn: false,
      isLoading: false,
      fullPage: true,
      topBanners: [],
      loader: "bars",
      displayMobileMenu: false,
      companyLogo: "",
      minicart: {
        timeoutInst: false,
        dropdownInst: "",
        secondsLeft: 0,
        warn2MinShown: false,
        warn5MinShown: false,
        extendCartShown: false,
        extendCartModal:{
          show: false,
          heading: "",
          content: "",
        },
        warnModal: {
          show: false,
          heading: "",
          content: "",
        },
      },
      popups: [],
      currentPopup: {
        id: "",
        show: false,
        content: ","
      },
      installPWAVisible: true
    };
  },
  computed: {
    ...mapState([
      "cartMeta",
      "cart",
      "wishList",
      "deleteProdModal",
      "deletePPModal",
    ]),
    ...mapGetters(["quoteItemCount", "wishListCount", "loggedinUserDetails", "isSalesRep", "isESales", "isEnrolledLoyalty"]),

    decodedSearch: {
      // getter
      get: function () {
        return decodeURIComponent(this.searchString);
      },
      // setter
      set: function (newValue) {
        this.searchString = newValue;
      },
    },

    getCompanyName() {
      return localStorage.getItem("company_name");
    },

    getCompanyLogo() {
      return this.companyLogo
        ? this.companyLogo
        : localStorage.getItem("company_logo");
    },

    countdownText() {
      if (this.minicart.secondsLeft) {
        const minutes = Math.floor(this.minicart.secondsLeft / 60)
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor(this.minicart.secondsLeft % 60)
          .toString()
          .padStart(2, "0");
        return `${minutes}:${seconds}`;
      } else {
        return `00:00`;
      }
    },
  },
  methods: {
    ...mapMutations(["hideDeleteProdWarn", "hideDeletePPWarn"]),
    ...mapActions([
      "fetchCartAndMeta",
      "fetchQuoteList",
      "fetchWishList",
      "clearCartApi",
      "clearCartApiByTimer",
      "deleteProduct",
      "deletePP",
    ]),

    fetchUnitType() {
      if (localStorage.getItem("fllUnitTypes") == null) {
        axios
          .get(
            process.env.VUE_APP_API_BASE + "auth/getFllProductsUnit",
            {}, // the data to post
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            localStorage.setItem(
              "fllUnitTypes",
              JSON.stringify(response.data.original)
            );
          });
      }
    },
    getProgramSettings() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getFLProgramSettings", {
          headers: {
            "Content-type": "application/json;multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.error === false) {
            localStorage.setItem(
              "fl_settings",
              JSON.stringify(response.data.settings)
            );
          }
        });
    },
    toggleMobileMenu() {
      var element = document.getElementById("install-pwa");
      this.installPWAVisible = false;
      if (element.getBoundingClientRect().height > 8) {
        this.installPWAVisible = true;
      }
      if (this.displayMobileMenu == true) {
        this.displayMobileMenu = false;
      } else {
        this.displayMobileMenu = true;
      }
    },
    fetchSettings() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getGlobalSettings", {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.companyLogo = response.data.settings.logo;
          localStorage.setItem(
            "site_settings",
            JSON.stringify(response.data.settings)
          );
          localStorage.setItem(
            "company_name",
            response.data.settings.company_name
          );
          localStorage.setItem("company_logo", response.data.settings.logo);
          localStorage.setItem(
            "meta_title_brand",
            response.data.settings.meta_title_brand
          );
          localStorage.setItem("meta_title", response.data.settings.meta_title);
          localStorage.setItem(
            "meta_description",
            response.data.settings.meta_description
          );
        });
    },
    shoppingCart() {
      window.location.href = "/shopping-cart";
      // this.$router.push({
      //   name: "Carts",
      // });
    },
    submitSearchForm() {
      // this.$router.push({
      //   name: "Search",
      //   query: { search: this.searchString },
      // });
      window.location.href = `/search?search=${this.searchString}`;
    },
    clearSearchForm() {
      this.searchString = ''
      this.showClearBtn = false
    },
    shippingCost(selected_shipping) {
      if (selected_shipping.cost > 0) {
        return "$" + parseFloat(selected_shipping.cost).toFixed(2);
      } else if (selected_shipping.cost == 0 && selected_shipping.is_tbd == 1)
        return "TBD";
      else if (selected_shipping.cost == 0 && selected_shipping.is_tbd == 0)
        return "FREE";
    },
    countDownTimer() {
      if (this.cartMeta.timer.secondsLeft) {
        this.minicart.timeoutInst = setTimeout(() => {
          const start = new Date(this.cartMeta.timer.localTime);
          const now = new Date();
          const secondsElapsed = (now - start) / 1000;
          let all_site_settings = JSON.parse(
            localStorage.getItem("site_settings")
          );
          if (!secondsElapsed) {
            this.minicart.secondsLeft = this.cartMeta.timer.secondsLeft;
          } else if (secondsElapsed < this.cartMeta.timer.secondsLeft) {
            this.minicart.secondsLeft =
              this.cartMeta.timer.secondsLeft - secondsElapsed;

            if (
              this.cartMeta.extendCartTimer &&
              !this.minicart.extendCartShown &&
              this.minicart.secondsLeft >=
                all_site_settings.extend_cart_timer_warning - 5 &&
              this.minicart.secondsLeft <=
                all_site_settings.extend_cart_timer_warning
            ) {
              this.minicart.extendCartShown = true;
              this.minicart.extendCartModal.show = true;
              this.minicart.extendCartModal.heading = "Extend Cart Timer";

              this.minicart.extendCartModal.content =
                all_site_settings.extend_cart_timer_message;
            }

            

            if (
              !this.minicart.warn2MinShown &&
              this.minicart.secondsLeft <=
                all_site_settings.cart_cutoff_warning_timer
            ) {
              this.minicart.warn2MinShown = true;
              this.minicart.warnModal.show = true;
              this.minicart.warnModal.heading = "Cutoff is expiring";

              this.minicart.warnModal.content =
                all_site_settings.cart_cutoff_warning_message;
            } else if (
              !this.minicart.warn5MinShown &&
              this.minicart.secondsLeft >=
                all_site_settings.cart_expiration_warning_timer - 5 &&
              this.minicart.secondsLeft <=
                all_site_settings.cart_expiration_warning_timer
            ) {
              this.minicart.warn5MinShown = true;
              this.minicart.warnModal.show = true;
              this.minicart.warnModal.heading = "Cart is expiring";
              this.minicart.warnModal.content =
                all_site_settings.cart_expiration_warning_message;
            }

            this.countDownTimer();
          } else {
            this.showLoader();
            this.minicart.secondsLeft = 0;
            this.clearCartApiByTimer();
            this.clearCartComments();
            this.hideLoader();
          }
        }, 1000);
      } else if (this.minicart.timeoutInst) {
        clearTimeout(this.minicart.timeoutInst);
        this.minicart.timeoutInst = null;
      }
    },
    hideWarnModal() {
      this.minicart.warnModal.show = false;
    },
    hideExtendCartModal() {
      this.minicart.extendCartModal.show = false;
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    extendCartTimer() {
      return axios
          .get(
            process.env.VUE_APP_API_BASE + "auth/extendCartTimer",
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.error === false) {
              this.triggerFlashAlert(response.data.message, "error");
            }
            this.fetchCartAndMeta()
            this.hideExtendCartModal()
            setTimeout(() => {
              this.minicart.extendCartShown = false            
            }, 5000);
          });
    },
    productDeleted(response) {
      this.showFlashAlert(response.msg, response.level);
      this.fetchCartAndMeta();
      this.isLoading = false;
    },
    PPDeleted(response) {
      this.showFlashAlert(response.msg, response.level);
      this.isLoading = false;
    },
    addToWishlist() {
      this.fetchWishList();
    },
    deleteFromWishlist() {
      this.fetchWishList();
    },
    displayFlash(response) {
      this.showFlashAlert(response.message, response.level);
    },
    initMiniCart() {
      this.$nextTick(function () {
        if (this.$refs.miniCartLink) {
          this.minicart.dropdownInst = new bootstrap.Dropdown("#miniCartLink");

          if (this.isTouchDevice()) {
            this.$refs.miniCartLink.addEventListener(
              "click",
              this.toggleMiniCart
            );
          } else {
            this.$refs.miniCartLink.addEventListener(
              "click",
              this.shoppingCart
            );

            this.$refs.miniCartLink.addEventListener(
              "mouseenter",
              this.showMiniCart
            );

            this.$refs.miniCartCont.addEventListener(
              "mouseleave",
              this.hideMiniCart
            );
          }
        }
      });
    },

    toggleMiniCart() {
      this.minicart.dropdownInst.toggle();
    },

    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    showMiniCart() {
      this.minicart.dropdownInst.show();
    },
    hideMiniCart() {
      this.minicart.dropdownInst.hide();
    },
    getPopups() {
      let  apiUrl = process.env.VUE_APP_API_BASE + "auth/getPopups";
      axios
        .post(
          apiUrl,
          {
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error === false) {
            this.popups = response.data.popups
            if (this.popups.length > 0) {
              this.currentPopup = {
                id: this.popups[0].id,
                content: this.popups[0].description,
                show: true,
              }
            }
          }
        });
    },
    hidePopup() {
      let  apiUrl = process.env.VUE_APP_API_BASE + "auth/popupViewed";
      axios
        .post(
          apiUrl,
          {
            id: this.currentPopup.id,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error === false) {
            this.popups = response.data.popups
            if (this.popups.length > 0) {
              this.currentPopup = {
                id: this.popups[0].id,
                content: this.popups[0].description,
                show: true,
              }
            }
          }
        });
      this.currentPopup.show = false;
    },
  },
  watch: {
    $route(to) {
      if ("search" in to.query) {
        this.searchString = to.query.search;
        return;
      }
      this.searchString = "";
    },
    "cartMeta.timer.secondsLeft": function () {
      this.countDownTimer();
    },
    "cartMeta.count": function () {
      this.initMiniCart();
    },
    "searchString": function() {
      if (this.searchString.length > 0) {
        this.showClearBtn = true
      } else {
        this.showClearBtn = false
      }
    }
  },
};
</script>

<style scoped>
.logo img {
  height: 36px;
  width: 239px
}
.menu-list ul li a:after {
  border: none;
}
.menu-list ul li a img {
  max-width: 120px;
}

#clearSearch {
  position: absolute;
  top: 8px;
  right: 65px;
}

div#srchBtn {
  position: absolute;
  right: 0;
  top: 0;
  height: 36px;
  color: #fff;
  padding: 8px 18px;
  background: #BBBBBA;
  cursor: pointer;
}

#search_input {
  background-color: #ebebea;
  border: none;
  box-shadow: none;
}

#search_input:focus {
  box-shadow: none;
}
.form-control {
  border-radius: 0px;
}

dl {
  width: 100%;
  overflow: hidden;
  background: #f7f7f7;
  padding: 0;
  margin: 0;
  border: 1px solid #ececec;
}
dt {
  float: left;
  width: 50%;
  /* adjust the width; make sure the total of both is 100% */
  background: #f7f7f7;
  padding: 0;
  margin: 0;
  text-align: right;
  border-right: 1px solid #ececec;
  font-weight: normal;
  padding-right: 5px;
  border-bottom: 1px solid #ccc;
}
dd {
  float: left;
  width: 50%;
  /* adjust the width; make sure the total of both is 100% */
  background: #f7f7f7;
  padding: 0;
  margin: 0;
  padding-right: 5px;
  text-align: right;
  border-bottom: 1px solid #ececec;
}
.box_unit_container {
  text-transform: capitalize;
}
.top-menu-primary ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.all_cart_containers {
  max-height: 50vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
}
.all_cart_containers > ul {
  display: block !important;
  width: 100%;
}
a.miniCartLink {
  text-decoration: none;
  outline: none;
}

.top-menu-primary ul {
  padding: 0;
  /* margin: 0 0 0 -24px; */
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

span#hint {
  margin-top: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: inline-block;
  cursor: pointer;
  margin-right: 15px;
}
.miniCartLink + .dropdown-menu {
  min-width: initial !important;
  width: 380px;
  max-width: 100vw;
  margin-top: -3px !important;
}
@media (max-width: 767px) {
  .header-top {
    padding-top: 15px;
  }
  .logo img {
    height: 32px;
    width: 212px;
  }
}
</style>
<style>
:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.top-134 {
  top: 134px !important;
}
</style>